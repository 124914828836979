import React from 'react';
import styled from 'styled-components';
import _ from 'lodash';
import { useRouter } from 'next/router';

import useColors from 'hooks/use-colors';
import useTranslation from 'hooks/use-translation';

import { Link, VerticalDivider } from 'components/core';
import { heading4 } from 'components/core/typography';
import DutchiePlusWordmark from 'assets/marketing/dutchie-plus/dutchie-plus-wordmark';
import { DutchieLogo } from 'assets/marketing/rebrand';
import ROUTES from 'src/routes';

const routes = ({ variant = `default`, colors, t, logoColor }) => {
  const { DISPENSARY_B2B, DISPENSARY_FEATURES, PLUS, PLUS_DOCS, ROOT } = ROUTES;
  const iconFillColors = {
    default: colors.primaryGreen,
    secondary: colors.white,
  };
  const routesList = {
    default: {
      routes: [
        {
          key: 'home',
          href: ROOT,
          route: ROOT,
          label: <DutchieLogo height='30px' fill={logoColor || colors.opal} />,
        },
        {
          key: 'shop',
          href: ROOT,
          route: ROOT,
          label: t('navigation.shopNow', 'Shop now'),
        },
        {
          key: 'dispensary',
          href: DISPENSARY_B2B,
          route: DISPENSARY_B2B,
          label: t('navigation.dispensary', 'For businesses'),
        },
      ],
    },
    secondary: {
      routes: [
        {
          key: 'home',
          href: ROOT,
          route: ROOT,
          label: (
            <>
              <DutchieLogo height='30px' fill={iconFillColors[variant]} />
              <VerticalDivider height='24px' />
              <span> {t('marketing.dispensaries', 'Dispensaries')} </span>
            </>
          ),
        },
        {
          key: 'features',
          href: DISPENSARY_FEATURES,
          route: DISPENSARY_FEATURES,
          label: t('navigation.features', 'Features'),
        },
      ],
    },
    plus: {
      routes: [
        {
          href: PLUS,
          route: PLUS,
          label: <DutchiePlusWordmark height='30px' />,
        },
        {
          href: PLUS,
          route: PLUS,
          label: t('navigation.features', 'Features'),
        },
        {
          href: `https://docs.dutchie.com${PLUS_DOCS}`,
          route: `https://docs.dutchie.com${PLUS_DOCS}`,
          label: t('navigation.docs', 'Docs'),
        },
      ],
    },
  };
  return routesList[variant].routes;
};

const MarketingNavigation = (props) => {
  const { variant = `default`, logoColor } = props;
  const colors = useColors();
  const router = useRouter();
  const { t } = useTranslation();
  const linkColors = {
    default: {
      nonactive: colors.black,
      active: colors.darkGrey,
    },
    careers: {
      nonactive: colors.black,
      active: colors.darkGrey,
    },
    secondary: {
      nonactive: colors.white,
      active: colors.white,
    },
    plus: {
      nonactive: colors.white,
      active: colors.white,
    },
  };
  const isSecondaryVariant = variant === `secondary`;
  return (
    <Nav>
      <LinkList flexEnd={isSecondaryVariant} marginRight={!isSecondaryVariant}>
        {_.map(routes({ variant, colors, t, logoColor }), (route) => (
          <ListItem key={route.href} className={isSecondaryVariant && route.href === ROUTES.ROOT && `nav-item-left`}>
            <LinkWrapper
              active={_.includes(router.route, _.lowerCase(route.key))}
              color={linkColors[variant]}
              underline={variant === `secondary`}
            >
              <StyledLink href={route.href} route={route.route}>
                <a href={route.href} aria-label={route.label}>
                  {route.label}
                </a>
              </StyledLink>
            </LinkWrapper>
          </ListItem>
        ))}
      </LinkList>
    </Nav>
  );
};

export default MarketingNavigation;

const LinkWrapper = styled.div`
  ${heading4};
  display: flex;
  align-items: center;
  color: ${({ active, color }) => (active ? color?.active : color?.nonactive)};
  transition: color linear 0.03s;

  &:hover,
  &:active {
    color: ${({ color }) => color?.active};
    &:not(:first-of-type) {
      ${({ underline }) => underline && `text-decoration: underline`};
    }
  }
  span {
    margin-left: 20px;
  }
  a {
    display: flex;
    place-items: center;
  }
`;

const LinkList = styled.ul`
  display: flex;
  align-items: center;
  list-style: none;
  margin: 0;
  padding: 0;
  ${({ flexEnd }) =>
    flexEnd &&
    `
    justify-content: flex-end
    align-items: flex-end;
  `};
  svg {
    ${({ marginRight }) => marginRight && `margin-right: 23px`};
  }
`;

const ListItem = styled.li`
  margin-left: 29px;
  &.nav-item-left {
    margin-right: auto;
  }
  &:last-of-type {
    margin-right: 29px;
  }
`;

const Nav = styled.nav`
  width: 100%;
  height: auto;
`;

const StyledLink = styled(Link)`
  ${heading4};
  display: flex;
  align-items: center;
  color: ${({ active, color }) => {
    if (active) {
      return color?.active;
    }
    return color?.nonactive;
  }};
  &:hover,
  &:active {
    color: ${({ color }) => color?.active};
    &:not(:first-of-type) {
      text-decoration: ${({ underline }) => underline && `underline`};
    }
  }
  span {
    margin-left: 20px;
  }
`;

import React, { useState, useCallback } from 'react';
import styled from 'styled-components';
import { color } from 'styled-system';
import _ from 'lodash';
import { useRouter } from 'next/router';
import Drawer from '@material-ui/core/Drawer';

import useColors from 'hooks/use-colors';
import useTranslation from 'hooks/use-translation';

import { Link } from 'components/core';
import { heading3 } from 'components/core/typography';
import { useModals, ModalNames } from 'components/modals';

import DutchiePlusWordmark from 'assets/marketing/dutchie-plus/dutchie-plus-wordmark';
import MenuIcon from 'assets/menu-icon';
import ExitIcon from 'assets/exit-icon';
import ROUTES from 'src/routes';
import { DISPENSARY_DEMO_LINK } from 'src/constants/external-links';
import { DutchieWordmark } from 'assets/marketing/rebrand';

const MobileMarketingNavigation = (props) => {
  const { variant = `default`, logoColor } = props;
  const { DISPENSARY_B2B, DISPENSARY_FEATURES, PLUS, PLUS_DEMO, PLUS_DOCS, ROOT } = ROUTES;
  const colors = useColors();
  const router = useRouter();
  const { openModal } = useModals();
  const { t } = useTranslation();
  const [selectedRoute, setSelectedRoute] = useState(router.route || `/home`);
  const [open, setOpen] = useState(false);

  const openLoginModal = useCallback(() => openModal(ModalNames.login), [openModal]);

  const routesList = {
    default: {
      routes: [
        {
          key: 'shop',
          href: ROOT,
          route: ROOT,
          label: t('nav.shopNow', 'Shop now'),
        },
        {
          key: 'dispensary',
          href: DISPENSARY_B2B,
          route: DISPENSARY_B2B,
          label: t('navigation.dispensary', 'For businesses'),
        },
      ],
    },
    secondary: {
      routes: [
        {
          key: 'home',
          href: ROOT,
          route: ROOT,
          label: t('navigation.home', 'Home'),
        },
        {
          key: 'features',
          href: DISPENSARY_FEATURES,
          route: DISPENSARY_FEATURES,
          label: t('navigation.features', 'Features'),
        },
        {
          key: 'demo',
          href: DISPENSARY_DEMO_LINK,
          route: DISPENSARY_DEMO_LINK,
          label: t('navigation.dispensaryDemo', 'Schedule a Demo'),
        },
      ],
    },
    plus: {
      routes: [
        {
          key: 'features',
          href: PLUS,
          route: PLUS,
          label: t('navigation.features', 'Features'),
        },
        {
          key: 'docs',
          href: `https://docs.dutchie.com${PLUS_DOCS}`,
          route: `https://docs.dutchie.com${PLUS_DOCS}`,
          label: `Docs`,
          passHref: true,
        },
        {
          key: 'demo',
          href: PLUS_DEMO,
          route: PLUS_DEMO,
          label: t('navigation.scheduleADemo', 'Schedule a demo'),
        },
        {
          key: 'login',
          label: `Log in`,
          onClick: openLoginModal,
        },
      ],
    },
  };
  const colorConfig = {
    default: {
      background: colors.white,
      color: colors.primaryBlack,
      fill: colors.grey[45],
      logoFill: logoColor || colors.opal,
    },
    secondary: {
      fill: colors.white,
      logoFill: colors.white,
    },
    plus: {
      background: colors.dutchiePlusBlue[10],
      color: colors.white,
      fill: colors.white,
      logoFill: colors.white,
    },
  };

  const DutchieRoute = () => {
    if (variant === 'plus') {
      return (
        <Link href={PLUS} route={PLUS}>
          <StyledLink
            color={colorConfig[variant]?.color}
            active={selectedRoute === PLUS}
            onClick={() => setSelectedRoute(PLUS)}
          >
            <DutchiePlusWordmark height='21px' fill={colors.opal} />
          </StyledLink>
        </Link>
      );
    }
    return (
      <Link href={ROOT} route={ROOT}>
        <StyledLink
          $color={colorConfig[variant]?.color}
          active={selectedRoute === ROOT}
          onClick={() => setSelectedRoute(ROOT)}
        >
          <DutchieWordmark height='21px' fill={colorConfig[variant]?.logoFill || colors.black} />
        </StyledLink>
      </Link>
    );
  };

  return (
    <nav>
      <MobileContainer displayShadow={variant !== 'secondary'} backgroundColor={colorConfig[variant]?.background}>
        <DutchieLinkContainer>{DutchieRoute()}</DutchieLinkContainer>
        <StyledDrawer
          open={open}
          onClose={() => setOpen(false)}
          onOpen={() => setOpen(true)}
          SlideProps={{ direction: `down` }}
          backgroundColor={colorConfig[variant]?.background}
        >
          <LinkList>
            {_.map(routesList[variant]?.routes, (route) => (
              <ListItem key={route.href}>
                <Link href={route.href} route={route.route} key={route.key} {...route}>
                  <StyledLink
                    active={
                      _.includes(selectedRoute, route.key) || (selectedRoute === PLUS && route.key === `features`)
                    }
                    $color={colorConfig[variant]?.color}
                    onClick={() => {
                      if (_.isFunction(route.onClick)) {
                        route.onClick();
                      } else {
                        setSelectedRoute(route.key);
                        setOpen(false);
                      }
                    }}
                  >
                    {route.label}
                  </StyledLink>
                </Link>
              </ListItem>
            ))}
          </LinkList>
        </StyledDrawer>
        <MenuButtonContainer onClick={() => setOpen(!open)}>
          {open ? (
            <div>
              <ExitIcon fill={colorConfig[variant]?.fill} />
            </div>
          ) : (
            <MenuIcon fill={colorConfig[variant]?.fill} />
          )}
        </MenuButtonContainer>
      </MobileContainer>
    </nav>
  );
};

export default MobileMarketingNavigation;

const DutchieLinkContainer = styled.div`
  margin-left: 25px;
`;

const MenuButtonContainer = styled.div`
  cursor: pointer;
  margin-right: 25px;
  div {
    height: 34px;
    width: 34px;
  }
`;

const MobileContainer = styled.div`
  width: 100%;
  height: 78px;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 1201;
  background: ${({ theme }) => theme.colors.white};
  ${({ displayShadow }) => displayShadow && `box-shadow: 0px 3px 5px rgba(11, 29, 41, 0.09)`};
  ${color};
`;

const StyledDrawer = styled(Drawer)`
  z-index: 100 !important;
  .MuiDrawer-paper {
    height: auto;
    width: 100%;
    margin-top: 78px;
    ${color};
  }
`;

const LinkList = styled.ul`
  display: flex;
  align-items: center;
  list-style: none;
  margin: 0;
  flex-direction: column;
  padding: 50px 0 0;
  font-weight: bold !important;
`;

const ListItem = styled.li`
  margin-bottom: 46px;
  font-size: 20px;
`;

const StyledLink = styled.a`
  ${heading3};
  line-height: 120%;
  color: ${({ $color, active, theme }) => (active ? theme.colors.darkGrey : $color)};
  cursor: pointer;
  &:hover,
  &:active {
    color: ${({ theme }) => theme.colors.darkGrey} !important;
  }
`;

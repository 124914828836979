/* eslint-disable max-len */
import React from 'react';

function DutchiePlusWordmarkSVG(props) {
  return (
    <svg viewBox='0 0 193 37' fill='none' {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M18.346 27.504c-.973 1.302-2.932 2.235-4.723 2.235-3.25 0-5.432-2.54-5.432-6.323 0-3.752 2.183-6.273 5.432-6.273 1.792 0 3.75.933 4.723 2.235v8.126zm0-15.103c-1.847-1.632-4.203-2.519-6.745-2.519C4.662 9.882 0 15.321 0 23.416 0 31.54 4.662 37 11.6 37c2.55 0 4.86-.876 6.746-2.544v1.939h8.04V1.214h-8.04V12.4zm26.867 14.681c-.659.756-2.253 2.223-4.706 2.223-2.6 0-3.66-1.05-3.66-3.626V10.455h-7.874v17.692c0 5.253 3.114 8.266 8.545 8.266a12.39 12.39 0 007.695-2.622v2.03h7.873V10.455h-7.873v16.627zm25.121 1.665c-.148.164-.81.484-1.67.484-1.086 0-1.472-.991-1.472-1.918V17.269h5.066v-7.083h-5.066v-6.58h-8.17v6.58h-4.136v7.083h4.135v11.74c0 4.774 2.782 7.404 7.834 7.404 3.301 0 5.093-.948 6.014-1.742l.372-.322-1.928-6.681-.979 1.08zM87.3 16.613c1.94 0 3.502.785 4.515 2.27l.517.759 5.383-5.018-.409-.557c-1.284-1.753-4.332-4.693-10.31-4.693-7.97 0-13.755 5.675-13.755 13.494 0 7.848 5.784 13.545 13.754 13.545 5.947 0 9.013-2.972 10.311-4.744l.409-.556-5.373-5.01-.52.741c-1.072 1.524-2.635 2.33-4.523 2.33-3.498 0-5.849-2.535-5.849-6.306 0-3.741 2.35-6.255 5.85-6.255zm28.703-7.098c-3.646 0-6.226 1.486-7.744 2.725V.721h-8.025v35.692h8.025V19.225c.647-.794 2.234-2.344 4.767-2.344 2.667 0 3.707 1.039 3.707 3.706v15.826h7.975V18.08c0-5.444-3.173-8.566-8.705-8.566zM133.68 0a4.662 4.662 0 00-4.653 4.661c0 2.598 2.087 4.713 4.653 4.713a4.713 4.713 0 004.704-4.713c0-2.57-2.11-4.661-4.704-4.661zm-3.933 36.413h7.918V10.455h-7.918v25.958zm31.482-24.616c-.111.425-.171.87-.171 1.329v4.728a5.22 5.22 0 006.115 5.142c.018.337.026.678.026 1.024v2.295h-17.943c.77 2.407 3.03 3.878 6.039 3.878 1.837 0 4.462-.739 5.924-2.149l.671-.647 3.785 5.563-.499.457C162.722 35.66 158.763 37 154.588 37c-8.14 0-13.824-5.585-13.824-13.583 0-7.716 5.769-13.534 13.419-13.534 2.691 0 5.076.678 7.046 1.915zm-7.046 4.892c-2.988 0-4.52 1.785-5.021 3.626h10.16c-.331-1.283-1.442-3.626-5.139-3.626z'
        fill='#fff'
      />
      <path
        d='M173.106 4.836V2.798s-.232-1.715 2.035-1.715h6.383s2.266-.152 2.266 2.27v5.375s-.139 1.02.786 1.02h5.458s2.868 0 2.868 2.825l.092 5.885s.231 2.548-2.174 2.594c-2.405.046-12.511 0-12.511 0s-2.659.172-2.659 2.698v2.816s0 .926 1.11.926h3.237s1.295.103 1.295-1.274v-3.59c0-.464.734-.835 1.157-.835.422 0 1.341.278 1.341.834v5.421s0 1.992-2.914 1.992h-6.059s-1.711 0-1.711-1.575v-4.587s-.278-5.374 5.365-5.374h9.62s2.267.139 2.267-1.251v-3.336s.185-1.668-1.758-1.668a579.9 579.9 0 00-4.949.046s-2.359.14-2.359-1.667V5.114s.047-1.482-2.035-1.482h-2.497s-1.11-.14-1.11 1.204v5.375s.138 2.085-2.521 2.108h-5.782s-1.179-.07-1.179 1.366v3.336s-.139 1.53 1.619 1.53h5.319s.74-.047.37.463l-1.064 1.529s-.301.555-.833.555h-6.29s-1.665.232-1.711-1.992v-7.598s-.047-1.807 2.081-1.76h6.799s.601 0 .601-.603l.047-4.309z'
        fill='#8298FC'
      />
    </svg>
  );
}

export default DutchiePlusWordmarkSVG;

import React from 'react';
import styled from 'styled-components';

import useUser from 'hooks/use-user';
import useTranslation from 'hooks/use-translation';
import routeTo from 'utils/route-to';

import { DISPENSARY_DEMO_LINK } from 'constants/external-links';

import { useModals, ModalNames } from 'components/modals';
import { Button, Link } from 'components/core';

export const ScheduleDemoButton = ({ colorVariant, route = DISPENSARY_DEMO_LINK }) => {
  const { t } = useTranslation();
  const handleOnClick = () => {
    routeTo({ url: route });
  };
  return (
    <ScheduleButtonContainer className='button-container'>
      <Button variant={colorVariant} onClick={handleOnClick}>
        {t('features.scheduleDemo', 'Schedule a Demo')}
      </Button>
    </ScheduleButtonContainer>
  );
};

export const LoginButton = ({ colorVariant }) => {
  const { openModal } = useModals();
  const User = useUser();
  const { t } = useTranslation();

  const handleOnClick = () => {
    openModal(ModalNames.login);
  };

  return (
    <LoginButtonContainer className='button-container'>
      {User?.exists ? (
        <Link href='/account'>
          {t('header.loggedIn', { defaultValue: 'Hi, {{firstName}}', firstName: User.profile.firstName })}
        </Link>
      ) : (
        <Button variant={colorVariant} onClick={handleOnClick}>
          {t('features.login', 'Login')}
        </Button>
      )}
    </LoginButtonContainer>
  );
};

const LoginButtonContainer = styled.div`
  display: flex;
  height: fit-content;
  margin-left: auto;
  z-index: 10;
  padding-right: 40px;

  a {
    font-weight: bold;
    color: ${({ theme }) => theme.colors.white};
    transition: color linear 0.03s;

    &:hover {
      color: ${({ theme }) => theme.colors.lightGrey};
      text-decoration: underline;
    }
  }
`;

const ScheduleButtonContainer = styled.div`
  display: flex;
  height: fit-content;
  margin-right: 46px;
  z-index: 10;
  min-width: 175px;
`;
